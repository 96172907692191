var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"MmhaxtxOT64LsGtDut8Mr"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// sentry.client.config.js or sentry.server.config.js
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://aa3d942ccdb6746694a64ff8613c35a8@o4505979832696832.ingest.us.sentry.io/4507734251077632',
  tracesSampleRate: 1.0, // Adjust this value to control how much of your traffic is monitored
});
